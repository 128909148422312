<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Data Blog</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Blog
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary float-right px-4 my-2"
          @click.prevent="blogForm"
        >
          Add Blog
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>

      <table class="table table-bordered table-hover" v-if="!loading">
        <thead class="thead-light">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Title</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="data.length == 0">
            <td colspan="7">Blog is Empty</td>
          </tr>
          <tr v-for="(blog, p) in data" :key="p">
            <th scope="row">{{ p + 1 }}</th>
            <td>{{ blog.title }}</td>
            <td>
              <div class="d-flex justify-content-around">
                <!-- <p
                  style="cursor: pointer"
                  class="btn btn-link"
                  @click.prevent="editBlog(blog.slug)"
                >
                  <span class="material-icons"> launch </span>
                </p> -->
                <p
                  style="cursor: pointer"
                  class="btn btn-link"
                  @click.prevent="deleteBlog(blog.id)"
                >
                  <span class="material-icons"> delete </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "Blog",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
    };
  },

  mounted() {
    this.getAllBlog();
  },

  methods: {
    getAllBlog() {
      this.loading = true;
      const endpoint = "blog";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.blog;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    blogForm() {
      this.$router.push("/admin/blogs/add");
    },

    deleteBlog(id) {
      Vue.swal({
        title: "Are you sure?",
        text: "This banner will be delete",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          const endpoint = `blog/delete/${id}`;
          this.$api.delete(endpoint, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              Vue.swal("Success", "Blogs deleted", "success").then(() => {
                this.getAllBlog();
              });
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    editBlog(id) {
      this.$router.push(`/admin/blogs/edit/${id}`);
    },
  },
};
</script>
